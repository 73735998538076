import { LOCAL_STORAGE_KEYS } from "features/Common/modules/Storage/modules/LocalStorage/constants/LocalStorage.constants";
import { getLocalStorageItem } from "features/Common/modules/Storage/modules/LocalStorage/utils/LocalStorage.utils";

export function getTasksCompletedPercentage() {
  const stepsData = JSON.parse(sessionStorage.getItem("whatsnext_steps_data"));

  if (stepsData) {
    const whatsNextInfo = getWhatsNextInfo();
    const totalTasks = stepsData.length;
    const totalCompletedTasks = getCompletedTaskLength(
      whatsNextInfo,
      stepsData
    );

    return Math.round((totalCompletedTasks / totalTasks) * 100);
  }

  return 0;
}

export function isAllStepsCompleted() {
  return getTasksCompletedPercentage() === 100 ? true : false;
}

export function getWhatsNextInfo() {
  const whatsNextInfo = getLocalStorageItem(LOCAL_STORAGE_KEYS.NEXT_STEPS);
  return whatsNextInfo ? JSON.parse(whatsNextInfo) : {};
}

function getCompletedTaskLength(whatsNextInfo, steps) {
  let completedTasksCount = 0;

  for (let key in whatsNextInfo) {
    if (whatsNextInfo[key] == true && steps.some((step) => step.id == key)) {
      completedTasksCount++;
    }
  }

  // +1 for creating an account
  completedTasksCount += 1;

  return completedTasksCount;
}
