import React from "react";
import {
  ThemeProvider,
  creatorToolTheme,
  Modal,
} from "@my-scoot/component-library-legacy";
import { PAYMENT_STATUS } from "./PaymentUtil";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Schedule from "@material-ui/icons/Schedule";
import Clear from "@material-ui/icons/Clear";
import module_style from "./Style.module.css";
import { is_empty } from "utils/validations";
import { getParameterByName } from "utils/Utils";
import { api, dataProvider } from "data";
import moment from "moment";

// TODO: fix props naming in this modal component
// and update everywhere it is used.
export const PaymentStatusModal = ({ payment_status, set_payment_status }) => {
  const [show_modal, set_show_modal] = React.useState(
    !is_empty(payment_status)
  );
  const [transaction_details, set_transaction_details] = React.useState(null);

  React.useEffect(() => {
    // @dev TODO: please change the way of accesing PAYMENT_STATUS[2] And use getRAUrlParams for urls
    (async () => {
      if (
        payment_status === PAYMENT_STATUS[2].value &&
        !is_empty(getParameterByName("transaction"))
      ) {
        const temp = await dataProvider.custom_request(
          `${api.ad_creation.order.transaction_details}/${getParameterByName(
            "transaction"
          )}`
        );
        if (temp.status === 200) {
          set_transaction_details(temp.data.order);
        }
      }
    })();
  }, []);

  const handleClose = () => {
    window.location.href = window.location.href.split("?")[0];
    set_show_modal(false);
    set_payment_status(null);
  };

  const renderBody = () => {
    switch (payment_status) {
      case PAYMENT_STATUS[8].value: {
        return (
          <div
            className={`d-flex flex-column justify-content-center align-items-center`}
          >
            <Clear
              onClick={handleClose}
              className={`${module_style.close_modal} pointer`}
            />

            <div
              style={{ backgroundColor: PAYMENT_STATUS[8].background_color }}
              className={`${module_style.svg_container} mt-4`}
            >
              <Cancel
                style={{ fill: PAYMENT_STATUS[8].color }}
                className={`${module_style.payment_svg}`}
              />
            </div>

            <div
              style={{ color: PAYMENT_STATUS[8].color }}
              className={`${module_style.header} mt-4`}
            >
              Payment failed!
            </div>

            <div className={`${module_style.description} mt-4 text-center`}>
              Oops! Something went wrong. Your payment wasn’t completed.
            </div>

            <div className={`${module_style.line} mt-4`}></div>

            <div className={`${module_style.disclaimer} mt-4 mb-4`}>
              Don’t worry, any amount that has been debited from your account
              will be refunded to you within 24 hrs.
            </div>
          </div>
        );
      }

      case PAYMENT_STATUS[2].value: {
        return (
          <div
            className={`d-flex flex-column justify-content-center align-items-center`}
          >
            <Clear
              onClick={handleClose}
              className={`${module_style.close_modal} pointer`}
            />

            <div
              style={{ backgroundColor: PAYMENT_STATUS[2].background_color }}
              className={`${module_style.svg_container} mt-4`}
            >
              <CheckCircle
                style={{ fill: PAYMENT_STATUS[2].color }}
                className={`${module_style.payment_svg}`}
              />
            </div>

            <div
              style={{ color: PAYMENT_STATUS[2].color }}
              className={`${module_style.header} mt-4`}
            >
              Payment Successful!
            </div>

            <div className={`${module_style.description} mt-4 text-center`}>
              An invoice will be sent in your email.
              <br />
              {getParameterByName("transaction") && (
                <span className={`action_text`}>
                  Transaction Number: {getParameterByName("transaction")}
                </span>
              )}
            </div>

            <div className={`${module_style.line} mt-4`}></div>

            {!is_empty(transaction_details) && (
              <div className={`ml-md-0 ml-4 mr-md-0 mr-4 mt-4 mb-4`}>
                <div
                  className={`d-flex justify-content-between align-items-center`}
                >
                  <div className={`${module_style.title}`}>
                    Total amount paid
                  </div>
                  <div className={`${module_style.value}`}>
                    {transaction_details.currency} {transaction_details.amount}
                  </div>
                </div>
                <div
                  className={`d-flex justify-content-between align-items-center mt-2`}
                >
                  <div className={`${module_style.title}`}>
                    Transaction date
                  </div>
                  <div className={`${module_style.value} ml-4`}>
                    {moment().format("DD MMMM, YYYY, hh:mm A")}
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      }

      case PAYMENT_STATUS[7].value:
      case PAYMENT_STATUS[1].value: {
        return (
          <div
            className={`d-flex flex-column justify-content-center align-items-center`}
          >
            <Clear
              onClick={handleClose}
              className={`${module_style.close_modal} pointer`}
            />

            <div
              style={{ backgroundColor: PAYMENT_STATUS[1].background_color }}
              className={`${module_style.svg_container} mt-4`}
            >
              <Schedule
                style={{ fill: PAYMENT_STATUS[1].color }}
                className={`${module_style.payment_svg}`}
              />
            </div>

            <div
              style={{ color: PAYMENT_STATUS[1].color }}
              className={`${module_style.header} mt-4`}
            >
              Payment under processing
            </div>

            <div className={`${module_style.description} mt-4 text-center`}>
              Oh, looks like your payment is taking some time.
            </div>

            <div className={`${module_style.line} mt-4`}></div>

            <div className={`${module_style.disclaimer} mt-4 mb-4`}>
              Don’t worry, we’ll notify you once your payment is successfully
              through. Although, if the payment fails, then any amount that has
              been debited from your account will be refunded to you within 24
              hrs.
            </div>
          </div>
        );
      }

      default: {
        return <></>;
      }
    }
  };

  return (
    <ThemeProvider theme={creatorToolTheme}>
      <Modal
        customFooter={<></>}
        customHeader={<></>}
        modalPaperClassName={`${module_style.payment_modal}`}
        open={show_modal}
      >
        <>{renderBody()}</>
      </Modal>
    </ThemeProvider>
  );
};
