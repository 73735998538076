window.PIL = undefined;
window.JuspayPaymentsSdk = undefined;

export const PAYMENT_STATUS = {
  1: {
    slug: "INITIATED",
    title: "Payment initiated",
    color: "rgba(255, 221, 153, 1)",
    background_color: "rgba(255, 221, 153, 0.3)",
    value: 1,
  },
  2: {
    slug: "SUCCESS",
    title: "Payment successful",
    color: "rgba(54, 179, 126, 1)",
    background_color: "rgba(54, 179, 126, 0.3)",
    value: 2,
  },
  7: {
    slug: "PROCESSING",
    title: "Payment processing",
    color: "rgba(255, 221, 153, 1)",
    background_color: "rgba(255, 221, 153, 0.3)",
    value: 7,
  },
  8: {
    slug: "FAILED",
    title: "Payment failed",
    color: "rgb(223, 99, 93, 1)",
    background_color: "rgb(223, 99, 93, 0.3)",
    value: 8,
  },
};

/**
 *
 * @param {*} order Coming from backend api
 * opens juspay payments link
 */
export const initiateJuspay = (order) => {
  // getting web as weblink to redirect coming from backend
  if (order?.web) window.location.href = order?.web;
};

/**
 *
 * @param {*} price
 * @param {*} rate
 * calculates gstAmount based on given params and return gst and total amount
 */
export const calculateGST = (price, rate) => {
  const gstAmount = (price * rate) / 100;
  const totalAmount = price + gstAmount;

  const gst = parseFloat(gstAmount.toFixed(2));
  const total = parseFloat(totalAmount.toFixed(2));

  return { gst, total };
};
