import { fixCountryCode, isInteger, is_empty } from "./validations";
import AWS from "aws-sdk";
import moment from "moment-timezone";
import constants from "../constants/constants";
import {
  getAuthData,
  getUserTimezone,
  isInternationalTimezone,
} from "./AuthUtil";
import api from "../data/APIs";
import dataProvider from "../data/dataProvider";
import firebase from "firebase";
import { HTTP_STATUS_SUCCESS_CODES } from "data/api.constants";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { REPORT_SOURCE } from "ui/pages/Reports/constants";
import { notification_color_keys } from "./hooks";
import * as Sentry from "@sentry/react";

export const UTC_OFFSET = 19800000; // The UTC offset of 5½ hours

// Whether the timestamp is in the past(includes current moment)
export const isTimePassed = (time) => {
  const targetDate = moment(time - UTC_OFFSET);
  const currentDate = moment();
  return currentDate.isAfter(targetDate) || currentDate.isSame(targetDate);
};

export const generateRandomString = (len = 8) => {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < len; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const roundOff = (value, places) =>
  (Math.round(value * 100) / 100).toFixed(places);

export const replace_all = (text, to_be_replaced, replace_with) => {
  return text.split(to_be_replaced).join(replace_with);
};

export const timeDiffCalc = (notificationdate, currentdate) => {
  let diffInMilliSeconds = Math.abs(notificationdate - currentdate) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";
  if (days > 0) {
    difference += days === 1 ? `${days} day ` : `${days} days `;
  }

  if (hours > 0 && days < 1) {
    difference += hours === 1 ? `${hours} hour ` : `${hours} hours `;
  }

  if (minutes > 0 && hours <= 0 && days < 1) {
    difference += hours === 1 ? `${minutes} minutes` : `${minutes} minutes`;
  } else if (minutes <= 0) {
    difference += "now";
  }

  return difference;
};

export const BreadCrumbLists = (sendData, from) => {
  const hasRoute = window.sessionStorage.getItem("fromRoute");
  switch (hasRoute) {
    case "Booked Session":
      sendData.unshift({
        title: "Booked Session",
        sendTo: "host/schedules/booked",
      });
      break;
    case "Past Session":
      sendData.unshift({
        title: "Past Session",
        sendTo: "host/schedules/past",
      });
      break;
    case "Upcoming Session":
      sendData.unshift({
        title: "Upcoming Session",
        sendTo: "host/schedules/upcoming",
      });
      break;
    case "All Customers":
      sendData.unshift({
        title: "All Customers",
        sendTo: "payments/exly/customers/list/v2",
      });
      break;
    case "All Transactions":
      sendData.unshift({
        title: "All Transactions",
        sendTo: api.transaction_list,
      });
      break;
    case "Manage Subscriptions":
      sendData.unshift({
        title: "Manage Subscriptions",
        sendTo: "payments/exly/subscription/list",
      });
      break;
    case "Refund History":
      sendData.unshift({
        title: "Refund History",
        sendTo: api.refund_history,
      });
      break;
    case "Merchandise":
      sendData.unshift({
        title: "Merchandise",
        sendTo: "payments/exly/merchandise/bookings",
      });
      break;
    case "Content Purchases":
      if (from === "newListing") {
        sendData = [
          {
            title: "Content Purchases",
            sendTo: "payments/exly/recorded/bookings",
          },
          { title: "Create Offering", sendTo: "" },
        ];
      } else if (from === "Create") {
        sendData.shift();
        const data = [
          {
            title: "Content Purchases",
            sendTo: "payments/exly/recorded/bookings",
          },
        ];
        data.forEach((item) => {
          sendData.unshift(item);
        });
      } else {
        sendData = [
          {
            title: "Content Purchases",
            sendTo: "payments/exly/recorded/bookings",
          },
        ];
      }
      break;
    case "Payment Link":
      sendData.splice(0, 1, { title: "Payment Link", sendTo: "PaymentsLinks" });
      break;
    default:
      break;
  }
  return sendData;
};

/**
 * @deprecated use DateConvert from src/utils/dateTimeUtils.js
 */
export const DateConvert = (record, source, timeRequired) => {
  return moment(record[source])
    .tz(getUserTimezone())
    .format(
      `${constants.displayDateYearFormat}${timeRequired ? ", hh:mm A" : ""}`
    );
};

export const parsePhoneNumber = (
  record,
  { countryCodeKey, phoneNumberKey } = {
    countryCodeKey: "country_code",
    phoneNumberKey: "phone_number",
  }
) => {
  if (!record) return "";
  const countryCode = fixCountryCode(
    countryCodeKey in record ? record[countryCodeKey] : ""
  );
  const phoneNumber = phoneNumberKey in record ? record[phoneNumberKey] : "";
  return `${countryCode}${phoneNumber}`;
};

export const getIfSlotsAvailable = (eventData) => {
  if (!eventData) return false;
  // To handle the console error when the page renders on SSR
  // TODO: Handle this in a middleware during refactoring
  // if (typeof eventType !== "number") return false;
  switch (eventData?.type) {
    case 0:
    case 3:
      return (
        eventData?.slots &&
        Array.isArray(eventData.slots) &&
        eventData.slots.length > 0 &&
        eventData.slots.filter(
          (slot) =>
            slot.spots_filled < eventData.max_spots &&
            !isTimePassed(slot.start_time * 1000)
        ).length > 0
      );
    case 1:
    case 2:
      return (
        eventData?.classes &&
        Array.isArray(eventData.classes) &&
        eventData.classes.length > 0 &&
        eventData.classes.filter(
          (classItem) => classItem.slots_filled < eventData.max_spots
        ).length > 0
      );
    case 6:
      return (
        (eventData?.total_inventory || 0) -
          (eventData?.purchased_inventory || 0) >
        0
      );
    case 4:
    case 5:
    default:
      return true;
  }
};

export const handleMobileInfo = (e) => {
  if (window.screen.width < 780) {
    e.stopPropagation();
    if (e.target.classList.contains("info")) {
      e.target.parentElement
        .querySelector(".tooltiptext")
        .setAttribute("style", "visibility: visible;");
    } else if (e.target.parentElement.classList.contains("info")) {
      e.target.parentElement.parentElement
        .querySelector(".tooltiptext")
        .setAttribute("style", "visibility: visible;");
    } else {
      e.target
        .querySelector(".tooltiptext")
        .setAttribute("style", "visibility: visible;");
    }
  }
};

function getBrowser() {
  const ua = navigator.userAgent;
  let tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }
  if (window.navigator.userAgent.indexOf("Edge") > -1) {
    tem = ua.match(/Edge\/(\d+)/);
    if (tem != null) {
      return { name: "Edge", version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: +M[1],
  };
}

export const isBrowserSupported = () => {
  const browser = getBrowser();
  let isSupported = true;

  if (browser.name === "Safari" && browser.version < 10) {
    isSupported = false;
  }

  if (browser.name === "Firefox" && browser.version < 78) {
    isSupported = false;
  }

  return isSupported;
};

/**
 * @deprecated use getRAUrlParams
 */
export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const getURLSearchParamsFromRouteProps = (props) =>
  new URLSearchParams(props.location.search);

export const getParamValueFromRouteProps = (props, param) =>
  getURLSearchParamsFromRouteProps(props).get(param);

export function serialize(obj) {
  const str = [];
  for (const p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export const xxl = window.screen.width > 1439;
export const is_desktop = window.screen.width > constants.mobile_width;

export const is_class = (listing) =>
  isInteger(listing?.type) &&
  (listing.type === constants.scheduleTypesId.group_class ||
    listing.type === constants.scheduleTypesId.rolling_class);

export const uploadToFirebase = async (file, key = "") => {
  return new Promise((resolve, reject) => {
    try {
      const config = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
      };

      if (!firebase.apps.length) {
        firebase.initializeApp(config);
      } else {
        firebase.app();
      }

      const storage = firebase.storage();
      let filename = !is_empty(key) ? key : `ad_creactive_${moment().unix()}`;
      let storageRef = storage.ref(`/exly`);
      let fileRef = storageRef.child(filename).put(file);
      fileRef.on(
        "state_changed",
        function progress() {},
        function error(err) {
          console.log("error", err);
        },
        function complete() {
          fileRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
            return resolve(downloadURL);
          });
        }
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const uploadToS3 = ({
  file,

  // why you should keep key short:
  // https://stackoverflow.com/questions/65271173/does-amazon-s3-have-a-limit-to-the-number-of-subfolders-you-create
  key,
  bucket,
  AWSConfig,
  contentType,
  abortSignal,
  onProgress,
  onAbort,
}) => {
  if (!key) throw new Error("'key' param not provided to uploadToS3 function");
  if (!file)
    throw new Error("'file' param not provided to uploadToS3 function");
  return new Promise((resolve, reject) => {
    try {
      AWS.config.update(AWSConfig || constants.AWS_CRED);

      let s3 = new AWS.S3();

      let params = {
        Bucket: bucket,
        Body: file,
        Key: key,
      };

      // only set ACL param for the deprecated bucket... newer ones should not be sent this param
      if (bucket === constants.DEPRECTAED_S3_BUCKET_STAGING_MYSCOOT_DOCS) {
        params.ACL = "public-read";
      }

      if (contentType) {
        params.ContentType = contentType;
      }

      const uploader = s3.upload(params, function (err, data) {
        //handle error
        if (err) {
          console.log("Error in uploading to s3", err);
          return reject(err);
        }

        //success
        if (data) {
          return resolve(data);
        }
      });

      if (onProgress) {
        uploader.on("httpUploadProgress", onProgress);
      }

      if (abortSignal)
        abortSignal.addEventListener("abort", () => {
          uploader.abort();
          if (onAbort) {
            onAbort();
          }
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getS3SignedUrl = (
  key,
  bucket = constants.DEPRECTAED_S3_BUCKET_STAGING_MYSCOOT_DOCS,
  creds = constants.AWS_CRED
) => {
  if (!key) throw new Error("'key' param not provided to uploadToS3 function");
  return new Promise((resolve, reject) => {
    try {
      AWS.config.update(creds);
      let s3 = new AWS.S3({ signatureVersion: "v4" });
      let params = { Bucket: bucket, Key: decodeURIComponent(key) };
      s3.getSignedUrl("getObject", params, function (err, data) {
        if (err) return reject("Error");
        if (data) return resolve(data);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const getOnboardingContent = () => {
  // TODO @Dhruva: fix the below issue
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    try {
      if (is_empty(getAuthData())) return resolve({});
      let stepsData =
        sessionStorage.getItem("whatsnext_steps_data") &&
        JSON.parse(sessionStorage.getItem("whatsnext_steps_data"));

      if (!stepsData) {
        stepsData = await dataProvider.custom_request(
          api.get_onboarding_content
        );

        if (stepsData && stepsData.status === 200) {
          if (stepsData?.data?.onboarding_constants?.next_steps) {
            stepsData = stepsData.data.onboarding_constants.next_steps;
            sessionStorage.setItem(
              "whatsnext_steps_data",
              JSON.stringify(stepsData)
            );
          }
        }
      }
      return resolve(stepsData);
    } catch (err) {
      console.log("getOnboardingContent err", err);
      return resolve({});
    }
  });
};

export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  }

  return "android";
}

export const getAnswerText = (item) => {
  let text_answer = is_empty(item.text_answer) ? "" : item.text_answer;
  if (item.question_type === 3 && !is_empty(item.choices)) {
    text_answer = item.choices.join(",");
  }
  if (item.question_type === 4) {
    text_answer = moment(item.text_answer)
      .tz(getUserTimezone())
      .format("DD/MM/YYYY");
  }
  if (item.question_type === 5) {
    text_answer = `https://${constants.DEPRECTAED_S3_BUCKET_STAGING_MYSCOOT_DOCS}.s3.${constants.AWS_CRED.region}.amazonaws.com/${item.text_answer}`;
  }
  return text_answer;
};

export const getMonthlyRevenue = (is_international) => {
  return is_international
    ? [
        `${constants.usd_currency}100 - ${constants.usd_currency}1,000`,
        `${constants.usd_currency}1,000 - ${constants.usd_currency}5,000`,
        `${constants.usd_currency}5,000 - ${constants.usd_currency}10,000`,
        `${constants.usd_currency}10,000 - ${constants.usd_currency}20,000`,

        `More than ${constants.usd_currency} 20,000`,
        "I have not started selling yet",
      ]
    : [
        `${constants.inr_currency}1,000 - ${constants.inr_currency}20,000`,
        `${constants.inr_currency}20,000 - ${constants.inr_currency}50,000`,
        `${constants.inr_currency}50,000 - ${constants.inr_currency}1,00,000`,
        `More than ${constants.inr_currency} 1,00,000`,
        "I have not started selling yet",
      ];
};

/**
 * @deprecated use `handleDateFormatV2`
 */
export const handleDateFormat = (date, time = true, year = true) => {
  if (time) {
    return moment(date).format(constants.displayDateYearTimeFormat);
  } else if (!year) {
    return moment(date).format(constants.displayDateNoYear);
  } else {
    return moment(date).format(constants.displayDateYearFormat);
  }
};

export const handleDateFormatV2 = (date, time = true, year = true) => {
  if (time && year) {
    return moment(date).format(constants.displayDateYearTimeFormat);
  } else if (time) {
    return moment(date).format(constants.displayDateTimeFormat);
  } else if (year) {
    return moment(date).format(constants.displayDateYearFormat);
  } else {
    return moment(date).format(constants.displayDateNoYear);
  }
};

export const handleInputDateFormat = () => {
  if (isInternationalTimezone()) {
    return constants.internationalDateFormat;
  } else return constants.domesticDateFormat;
};

export const openPricingPage = () => {
  window.open(process.env.REACT_APP_PRICING_PAGE_URL);
};

export const VOWELS = new Set(["a", "e", "i", "o", "u"]);

/**
 * @returns pluralised `word` by appending an "s" when the `count > 0`
 */
export const pluralise = (word, count) => {
  if (!word) return "";

  const len = word.length;
  const hasYAtEnd = word[len - 1].toLowerCase() === "y";
  const hasVowelBeforeY = VOWELS.has(word[len - 2].toLowerCase());

  if (hasYAtEnd && !hasVowelBeforeY) {
    const splittedWord = word.slice(0, -1);
    return count > 1 ? `${splittedWord}ies` : word;
  }

  return count > 1 ? `${word}s` : word;
};

export const ellipsize = (text, length) =>
  text?.length > length ? `${text?.slice(0, length)}...` : text;

/**
 *
 * @param {*} htmlString html string
 * @returns parsed text string extracted from the html string where <p> tags are considered as new lines & spaces are kept intact
 */
export const getStringFromHtml = (htmlString) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;

  // Iterate over the child nodes and build the converted string
  let convertedString = "";
  for (let i = 0; i < tempElement.childNodes.length; i++) {
    const node = tempElement.childNodes[i];

    // Process only <p> tags
    if (node.nodeName === "P") {
      // Append the inner text of the <p> tag
      convertedString += node.textContent.trim();
    }

    // Append a newline character after each <p> tag
    convertedString += "\n";
  }

  return convertedString;
};

export function convertCiteTags(htmlString, identifier = false) {
  const regex = /(<cite[^>]*>)(.*?)(<\/cite>)/g;
  const modifiedString = htmlString.replace(
    regex,
    function (match, p1, p2, p3) {
      if (p2.startsWith("{{") && p2.endsWith("}}")) {
        return match; // Do nothing if value is already in {{value}} format
      } else if (p2.startsWith("$${{") && p2.endsWith("}}$$")) {
        return match; // Do nothing if value is already in $$value$$ format
      }

      // in case of email broadcast p2 is expected to be in snake case("a_b_c") but it is coming by space seperated("a b c")
      if (identifier) {
        p2 = p2.replace(/\s/g, "_"); // replace spaces with underscores
      }

      let modifiedValue = `{{${p2}}}`;

      // in case of email broadcast RTEvariable format is decided as "$${{value}}$$"
      if (identifier) {
        modifiedValue = "$$" + modifiedValue + "$$"; // add $$ as start and end
      }

      return `${p1}${modifiedValue}${p3}`;
    }
  );
  return modifiedString;
}

/**
Deeply compares two objects for equality.
@param {object} obj1 - The first object to compare.
@param {object} obj2 - The second object to compare.
@param {boolean} serialize - serialize the objects into JSON strings and then compare those strings
to perform a deep comparison of their properties' values.
@returns {boolean} - Returns true if the objects are deeply equal, false otherwise.
*/
export const deepEqual = (obj1, obj2, serialize = false) => {
  // Check if both objects are null
  if (obj1 === null && obj2 === null) {
    return true;
  }

  // Check if either object is null
  if (obj1 === null || obj2 === null) {
    return false;
  }

  // Check if both objects are of type object
  if (typeof obj1 === "object" && typeof obj2 === "object") {
    // If serialization is requested, use JSON.stringify for comparison
    if (serialize) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if each property value is deeply equal
    for (let key of keys1) {
      if (!deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  // If not objects, compare using strict equality
  return obj1 === obj2;
};

/*
 * @param value number
 * @returns ordinal suffix
 */
export const getOrdinalSuffix = (value) => {
  const suffixIndex =
    (value > 3 && value < 21) || value % 10 > 3 ? 0 : value % 10;
  return value > 0 ? ["th", "st", "nd", "rd"][suffixIndex] : "";
};

/**
 * TODO: @harshit
 *
 * export function isRequestSuccessful
 * export const redirectToComposeEmail
 * in functions ko based on util
 * move kar dena
 * and
 * 2.  export const redirectToWhatsappBroadcast
 * can be made into url generator in src/constants/urlPaths.js (edited)
 */

/**
 * Checks if an HTTP request is successful based on the status code.
 *
 * @param {number} statusCode - The HTTP status code to check.
 * @returns {boolean} True if the status code indicates success, otherwise false.
 */
export function isRequestSuccessful(statusCode) {
  return Object.values(HTTP_STATUS_SUCCESS_CODES).includes(statusCode);
}

/**
 * Redirects the user to the compose email page.
 *
 * @returns {void}
 */
export const redirectToComposeEmail = () => {
  window.location.href = `${window.location.origin}/#/${
    app_route_ids[app_route_keys.compose_email]
  }`;
};

/**
 * Redirects the user to the WhatsApp broadcast page with a preselected phone number.
 *
 * @param {Object} options - An object containing the phone number to preselect.
 * @param {string} options.phoneNumber - The phone number to preselect in the URL.
 * @returns {void}
 */
export const redirectToWhatsappBroadcast = ({ phoneNumber }) => {
  window.location.href = `${window.location.origin}/#/whatsapp-broadcasts/select?preselected_numbers=${phoneNumber}`;
};

// function to export large listing data (report is emailed from BE )
export const exportLargeListingData = async ({
  reportType,
  filterValues,
  notify,
  isEmpty,
}) => {
  //nothing to export as data is empty(isEmpty)
  if (isEmpty) return;
  let query = {
    requested_report_type: reportType,
    report_source: REPORT_SOURCE.CREATOR_TOOL_REPORTS,
  };
  if (!is_empty(filterValues)) {
    query = {
      ...query,
      filters_data: filterValues,
    };
  }
  const response = await dataProvider.custom_request(
    api.upload_report,
    "POST",
    query
  );
  if (response.status === 200) {
    notify(
      "Report will be emailed to you in about an hour",
      notification_color_keys.success
    );
  } else {
    const errorMessage =
      response.message ||
      "Unable process export request as of now. Please try again later.";
    notify(errorMessage, notification_color_keys.error);
    Sentry.captureMessage(`Error while exporting report : ${errorMessage} `);
  }
};

/**
 * Opens a new browser window with the specified URL, centered on the screen.
 *
 * @param {string} urlToOpen - The URL to open in the new window.
 * @returns {void}
 */
export const handleOpenNewWindow = (urlToOpen) => {
  var screenWidth = window.screen.width;
  var screenHeight = window.screen.height;
  var windowWidth = 800;
  var windowHeight = 600;
  var left = (screenWidth - windowWidth) / 2;
  var top = (screenHeight - windowHeight) / 2;
  window.open(
    urlToOpen,
    "NewWindow",
    "width=" +
      windowWidth +
      ",height=" +
      windowHeight +
      ",left=" +
      left +
      ",top=" +
      top
  );
};
